import { createCancelToken } from '@apis/ServiceController';
import IconAdd3 from '@icons/IconAdd3';
import { paymentService } from '@services/paymentService';
import useHasChanged from '@utils/hooks/useHasChanged';
import ModalCreateCard from '@views/profile/Info/BankComponents/ModalCreateCard';
import ContentLoading from '@views_admin/ContentLoading';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import CardItem from './CardItem';

const Cards = memo(({ value, onLoadDefault, onSelectCard }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [data, setData] = useState({
      content: [],
      default: null,
   });
   const [loading, setLoading] = useState(false);
   const canceller = useRef();
   const hasDefaultChanged = useHasChanged(data.default);

   useEffect(() => {
      if (hasDefaultChanged && data.default) {
         onLoadDefault && onLoadDefault(data.default);
      }
   }, [hasDefaultChanged, data.default, onLoadDefault]);

   const loadData = async () => {
      canceller.current = createCancelToken();

      await setLoading(true);
      const response = await paymentService.getAllCards(null, canceller.current?.token);
      if (response.isSuccess) {
         const defaultItem = response.data.find((item) => item.default);
         setData({
            content: response.data || [],
            default: defaultItem || null,
         });
      }
      setLoading(false);
   };

   useEffect(() => {
      loadData();

      return () => {
         if (canceller.current) {
            canceller.current.cancel();
         }
      };
   }, []);

   const closeModal = useCallback(() => {
      setIsModalOpen(false);
   }, []);

   const onAddSuccess = useCallback(async () => {
      await loadData();
      closeModal();
   }, [closeModal]);

   const reload = useCallback(async () => {
      await loadData();
   }, []);

   const renderButtonAdd = (content) => {
      return (
         <>
            <button
               className="checkout-address__btn-add"
               onClick={() => setIsModalOpen(true)}
            >
               <IconAdd3 />
               {content}
            </button>
            <ModalCreateCard
               visible={isModalOpen}
               onClose={closeModal}
               onSuccess={onAddSuccess}
            />
         </>
      );
   };

   return (
      <div className="checkout-section__card">
         {/* <ContentLoading isLoading={loading} /> */}
         <>
            {data.content?.length > 0 && (
               <div className="checkout-section__item-list">
                  {data.content.map((item, index) => {
                     return (
                        <CardItem
                           key={item.uid}
                           {...item}
                           isSelected={item.uid === value}
                           reload={reload}
                           onSelectCard={onSelectCard}
                        />
                     );
                  })}
               </div>
            )}
            {renderButtonAdd(
               data.content.length === 0 ? 'Add new card' : 'Add other card'
            )}
         </>
      </div>
   );
});

export default Cards;
