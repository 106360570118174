import { getDisplayAddress } from '@utils/common';
import { ModalUpdateAddress } from '@views/profile/Info/AddressComponents/AddressItem';
import React, { memo, useCallback, useMemo, useState } from 'react';

const AddressItem = memo(
   ({ showSelect, isSelected, reload, onSelectAddress, ...props }) => {
      const [isModalOpen, setIsModalOpen] = useState(false);

      const initialValues = useMemo(() => {
         return { ...props, country_code: props?.country?.code || '' };
      }, [props]);

      const onUpdateSuccess = useCallback(async () => {
         await reload();
         setIsModalOpen(false);
      }, [reload]);

      return (
         <div className="checkout-section__card-item checkout-address">
            {showSelect && (
               <div
                  className={`checkout-section__card-item--select ${
                     isSelected ? 'selected' : ''
                  }`}
                  onClick={() => onSelectAddress(props.uid, props)}
               />
            )}
            <div className="checkout-address__item-info">
               <label>
                  {props.full_name}・{props.phone_number}
               </label>
               <p>{getDisplayAddress(props)}</p>
               {props.default && <span>Default</span>}
            </div>
            <button
               className="checkout-address__edit-btn"
               onClick={() => setIsModalOpen(true)}
            >
               Edit
            </button>
            <ModalUpdateAddress
               visible={isModalOpen}
               initialValues={initialValues}
               onClose={() => setIsModalOpen(false)}
               onSuccess={onUpdateSuccess}
            />
         </div>
      );
   }
);

export default AddressItem;
