import React from 'react';

const IconChange = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M3.30861 0.0379391C3.24964 0.0623391 3.19438 0.0985084 3.14645 0.146447L0.146446 3.14645C-0.0488157 3.34171 -0.0488157 3.65829 0.146446 3.85355C0.341709 4.04882 0.658291 4.04882 0.853554 3.85355L3 1.70711V12.5C3 12.7761 3.22386 13 3.5 13C3.77614 13 4 12.7761 4 12.5V1.70711L6.14645 3.85355C6.34171 4.04882 6.65829 4.04882 6.85355 3.85355C7.04882 3.65829 7.04882 3.34171 6.85355 3.14645L3.85355 0.146447C3.80562 0.0985084 3.75036 0.0623391 3.69139 0.0379391C3.63777 0.0157521 3.58106 0.00329661 3.52398 0.000572205C3.516 0.000190735 3.508 0 3.5 0C3.43221 0 3.36756 0.0134926 3.30861 0.0379391ZM9.14645 12.1464C9.34171 11.9512 9.65829 11.9512 9.85355 12.1464L12 14.2929V3.5C12 3.22386 12.2239 3 12.5 3C12.7761 3 13 3.22386 13 3.5V14.2929L15.1464 12.1464C15.3417 11.9512 15.6583 11.9512 15.8536 12.1464C16.0488 12.3417 16.0488 12.6583 15.8536 12.8536L12.8536 15.8536C12.8056 15.9015 12.7504 15.9377 12.6914 15.9621C12.6378 15.9842 12.5811 15.9967 12.524 15.9994C12.516 15.9998 12.508 16 12.5 16M12.3086 15.9621C12.2496 15.9377 12.1944 15.9015 12.1464 15.8536L9.14645 12.8536C8.95118 12.6583 8.95118 12.3417 9.14645 12.1464M12.497 16C12.4303 15.9996 12.3667 15.9861 12.3086 15.9621L12.497 16Z"
         fill="currentColor"
      />
   </svg>
);

export default IconChange;
