import { createCancelToken } from '@apis/ServiceController';
import IconAdd3 from '@icons/IconAdd3';
import IconChange from '@icons/IconChange';
import { addressService } from '@services/addressService';
import { getDisplayAddress } from '@utils/common';
import useHasChanged from '@utils/hooks/useHasChanged';
import ModalCreateAddress from '@views/profile/Info/AddressComponents/ModalCreateAddress';
import ContentLoading from '@views_admin/ContentLoading';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import AddressItem from './AddressItem';

const AddressPayment = memo(({ value, onLoadDefault, onSelectAddress }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [showAll, setShowAll] = useState(false);
   const [data, setData] = useState({
      content: [],
      default: null,
   });
   const [loading, setLoading] = useState(false);
   const canceller = useRef();
   const hasDefaultChanged = useHasChanged(data.default);

   useEffect(() => {
      if (hasDefaultChanged && data.default) {
         onLoadDefault && onLoadDefault(data.default);
      }
   }, [hasDefaultChanged, data.default, onLoadDefault]);

   const loadData = async () => {
      canceller.current = createCancelToken();

      await setLoading(true);
      const response = await addressService.getAddressMatrix(
         {
            page: 1,
            page_size: 10000,
         },
         canceller.current?.token
      );
      if (response.isSuccess) {
         const defaultItem = response.data.content.find((item) => item.default);
         setData({
            content: response.data?.content || [],
            default: defaultItem || null,
         });
      }
      setLoading(false);
   };

   useEffect(() => {
      loadData();

      return () => {
         if (canceller.current) {
            canceller.current.cancel();
         }
      };
   }, []);

   const closeModal = useCallback(() => {
      setIsModalOpen(false);
   }, []);

   const onAddSuccess = useCallback(async () => {
      await loadData();
      closeModal();
   }, [closeModal]);

   const reload = useCallback(async () => {
      await loadData();
   }, []);

   const renderButtonAddAdress = (content) => {
      return (
         <>
            <button
               className="checkout-address__btn-add"
               onClick={() => setIsModalOpen(true)}
            >
               <IconAdd3 />
               {content}
            </button>
            <ModalCreateAddress
               visible={isModalOpen}
               onClose={closeModal}
               onSuccess={onAddSuccess}
            />
         </>
      );
   };

   return (
      <div className="checkout-section__card">
         {/* <ContentLoading isLoading={loading} /> */}
         {!showAll ? (
            <>
               {data.default ? (
                  <>
                     <div className="checkout-section__item-list">
                        <AddressItem
                           {...data.default}
                           reload={reload}
                           onSelectAddress={onSelectAddress}
                        />
                     </div>
                     <button
                        className="checkout-address__btn-change-address"
                        onClick={() => setShowAll(true)}
                     >
                        <IconChange />
                        Change address
                     </button>
                  </>
               ) : (
                  renderButtonAddAdress('Add address')
               )}
            </>
         ) : (
            <>
               {data.content?.length > 0 && (
                  <div className="checkout-section__item-list">
                     {data.content.map((item, index) => {
                        return (
                           <AddressItem
                              key={item.uid}
                              {...item}
                              showSelect={true}
                              isSelected={item.uid === value}
                              reload={reload}
                              onSelectAddress={onSelectAddress}
                           />
                        );
                     })}
                  </div>
               )}
               {renderButtonAddAdress(
                  data.content.length === 0 ? 'Add address' : 'Add other address'
               )}
            </>
         )}
      </div>
   );
});

export default AddressPayment;
