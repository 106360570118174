import IconBrandCards from '@icons/IconBrandCards';
import { getPrevDigitsCard } from '@utils/common';
import React, { memo, useState } from 'react';

const CardItem = memo(({ isSelected, onSelectCard, ...props }) => {
   return (
      <div className="checkout-section__card-item checkout-card">
         <div
            className={`checkout-section__card-item--select ${
               isSelected ? 'selected' : ''
            }`}
            onClick={() => onSelectCard(props.uid)}
         />
         <div className="checkout-card__number">
            {getPrevDigitsCard(props?.brand)}
            {props.last4}
         </div>
         <div className="checkout-card__brand">
            <IconBrandCards brand={props?.brand} />
         </div>
      </div>
   );
});

export default CardItem;
