import React from 'react';

const IconSuccess72 = (props) => (
   <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M71 36C71 55.33 55.33 71 36 71C16.67 71 1 55.33 1 36C1 16.67 16.67 1 36 1C55.33 1 71 16.67 71 36Z"
         stroke="currentColor"
         strokeWidth={2}
      />
      <path
         d="M23.2071 35.2929L22.5 34.5858L21.0858 36L21.7929 36.7071L23.2071 35.2929ZM31.5 45L30.7929 45.7071L31.5 46.4142L32.2071 45.7071L31.5 45ZM50.2071 27.7071L50.9142 27L49.5 25.5858L48.7929 26.2929L50.2071 27.7071ZM21.7929 36.7071L30.7929 45.7071L32.2071 44.2929L23.2071 35.2929L21.7929 36.7071ZM32.2071 45.7071L50.2071 27.7071L48.7929 26.2929L30.7929 44.2929L32.2071 45.7071Z"
         fill="currentColor"
      />
   </svg>
);

export default IconSuccess72;
