import { Row, Col } from 'antd';
import { Link, navigate } from 'gatsby';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Footer from '../../views/default-layout/footer/Footer';
import SEO from '../../components/Common/SEO';
import constant from '../../utils/constant';
import { ShippingMethod } from '@utils/enums/ShippingMethod';
import { useSelector } from 'react-redux';
import { getCartSelector, getProfileSelector } from '@utils/selectors';
import AddressPayment from '@views/checkout/AddressPayment';
import Cards from '@views/checkout/Cards';
import SelectShipping from '@views/checkout/SelectShipping';
import OrderReview from '@views/checkout/OrderReview';
import PaymentSuccess from '@views/checkout/PaymentSuccess';
import ContentLoading from '@views_admin/ContentLoading';
import { paymentService } from '@services/paymentService';
import { message } from '@components/Message';

const index = memo(() => {
   const { data: userProfile, loading: profileLoading } = useSelector(getProfileSelector);
   const { cart, items, loading: cartLoading } = useSelector(getCartSelector);
   const appliedCoupon = localStorage[constant.COUPONS_VARIABLE];

   const [data, setData] = useState({
      address_uid: '',
      shipping_type: ShippingMethod.STANDARD,
      card_uid: '',
      coupon_codes: [],
   });
   const [countryCode, setCountryCode] = useState('');
   const [submitting, setSubmitting] = useState(false);
   const [paymentSuccessInfo, setPaymentSuccessInfo] = useState(null);
   const [discountInfo, setDiscountInfo] = useState(null);

   useEffect(() => {
      if (!profileLoading && !userProfile) {
         navigate(constant.ROUTE_CART);
      }
      if (!cartLoading && (!cart?.uid || !items?.length)) {
         navigate(constant.ROUTE_CART);
      }
   }, [profileLoading, userProfile, cartLoading, cart, items]);

   useEffect(() => {
      if (appliedCoupon) {
         try {
            const { codes: coupons = [], discountInfo } = JSON.parse(appliedCoupon) || {};
            if (coupons?.length) {
               let coupon_codes = coupons.map((i) => i);
               if (coupon_codes.length) {
                  setDiscountInfo(discountInfo);
                  setData((prev) => ({ ...prev, coupon_codes }));
               }
            }
         } catch (err) {
            console.log(err);
         }
         localStorage.removeItem(constant.COUPONS_VARIABLE);
      }
   }, [appliedCoupon]);

   const setDefaultAddress = useCallback((defaultData) => {
      if (defaultData.uid) {
         setData((prevData) => ({
            ...prevData,
            address_uid: defaultData.uid,
         }));
         setCountryCode(defaultData.country?.code);
      }
   }, []);
   const setDefaultCard = useCallback((defaultData) => {
      if (defaultData.uid) {
         setData((prevData) => ({
            ...prevData,
            card_uid: defaultData.uid,
         }));
      }
   }, []);

   const onSelectAddress = useCallback((uid, info) => {
      setData((prevData) => ({
         ...prevData,
         address_uid: uid,
      }));
      setCountryCode(info?.country?.code);
   }, []);
   const onSelectCard = useCallback((uid) => {
      setData((prevData) => ({
         ...prevData,
         card_uid: uid,
      }));
   }, []);

   const onSelectShipping = useCallback((value) => {
      setData((prevData) => ({
         ...prevData,
         shipping_type: value,
      }));
   }, []);

   const submitPayment = useCallback(async () => {
      await setSubmitting(true);
      const response = await paymentService.checkout(data);
      if (response.isSuccess) {
         setPaymentSuccessInfo(response.data);
      } else {
         message.error(response.message);
      }

      setSubmitting(false);
   }, [data]);

   const loading = useMemo(() => {
      return profileLoading || cartLoading;
   }, [profileLoading, cartLoading]);

   // coupon
   const { discount, localDiscount } = useMemo(() => {
      return {
         discount: discountInfo?.discount || 0,
         localDiscount: discountInfo?.localDiscount || 0,
      };
   }, [discountInfo]);

   console.log(data);

   return (
      <>
         <SEO title={'Check out'} />
         <ContentLoading
            className="checkout-page__loading"
            isLoading={loading || submitting}
         />
         {userProfile && cart && !paymentSuccessInfo && (
            <>
               <div className="checkout-page">
                  <Row gutter={30} justify="center">
                     <Col lg={10} md={10} sm={24} xs={24}>
                        <div className="checkout-section">
                           <label className="checkout-section__title">
                              1. Shipping information
                           </label>
                           <AddressPayment
                              value={data.address_uid}
                              onLoadDefault={setDefaultAddress}
                              onSelectAddress={onSelectAddress}
                           />
                        </div>
                        <div className="checkout-section">
                           <label className="checkout-section__title">
                              2. Shipping method
                           </label>
                           <SelectShipping
                              value={data.shipping_type}
                              onSelect={onSelectShipping}
                           />
                        </div>
                        <div className="checkout-section">
                           <label className="checkout-section__title">
                              3. Payment with card
                           </label>
                           <Cards
                              value={data.card_uid}
                              onLoadDefault={setDefaultCard}
                              onSelectCard={onSelectCard}
                           />
                        </div>
                     </Col>
                     <Col lg={2} md={2} sm={0}></Col>
                     <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="checkout-review">
                           <div className="checkout-review__header">
                              <label>Oder Review</label>
                              <Link to={`${constant.ROUTE_CART}/`}>Edit Oder</Link>
                           </div>
                           <OrderReview
                              data={data}
                              discount={discount}
                              localDiscount={localDiscount}
                              countryCode={countryCode}
                              submitPayment={submitPayment}
                           />
                        </div>
                     </Col>
                  </Row>
               </div>
               <Footer />
            </>
         )}
         {paymentSuccessInfo && <PaymentSuccess info={paymentSuccessInfo} />}
      </>
   );
});

export default index;
