import React, { memo, useEffect, useRef } from 'react';
import IconSuccess72 from '@icons/IconSuccess72';
import { Link } from 'gatsby';
import constant from '@utils/constant';
import { cartService } from '@services/cartService';

const PaymentSuccess = memo(({ info }) => {
   const cartCanceler = useRef(null);

   useEffect(() => {
      const reloadCart = async () => {
         cartCanceler.current = await cartService.getDetails();
      }
      reloadCart();
      return () => {
         if (cartCanceler.current) {
            cartCanceler.current.cancel();
         }
      };
   }, [])

   return (
      <div className="payment-success-transaction">
         <IconSuccess72 />
         <label className="payment-success-transaction__message">
            Payment successfull!
         </label>
         <label className="payment-success-transaction__desc">
            Thank you for shopping with us! <br />
            We will be processing your order soon. <br />
            {info?.shipment_code && (
               <>
                  Order ID:{' '}
                  <span className="order-id">
                     {`${info.shipment_code}`.toUpperCase()}
                  </span>
               </>
            )}
         </label>
         <div className="payment-success-transaction__actions">
            <Link className="common-button__outline" to={constant.ROUTE_PROFILE_ORDER}>
               View Order
            </Link>
            <Link className="common-button" to={constant.ROUTE_PRODUCTS}>
               Continue Shopping
            </Link>
         </div>
      </div>
   );
});

export default PaymentSuccess;
