import { ShippingMethod } from '@utils/enums/ShippingMethod';
import React, { memo } from 'react';

const shippingMethods = [
   {
      label: 'Standard',
      value: ShippingMethod.STANDARD,
   },
   {
      label: 'Fast',
      value: ShippingMethod.FAST,
   },
];

const SelectShipping = memo(({ value, onSelect }) => {
   return (
      <div className="checkout-section__card">
         <div className="checkout-section__item-list">
            {shippingMethods.map((item) => {
               return (
                  <div
                     className="checkout-section__card-item checkout-shipping"
                     key={item.value}
                  >
                     <div
                        className={`checkout-section__card-item--select ${
                           value === item.value ? 'selected' : ''
                        }`}
                        onClick={() => onSelect(item.value)}
                     />
                     <div className="checkout-shipping__item-info">
                        <label>{item.label}</label>
                     </div>
                  </div>
               );
            })}
         </div>
      </div>
   );
});

export default SelectShipping;
