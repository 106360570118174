import Currency from '@components/Currency';
import { shippingService } from '@services/shippingService';
import constant from '@utils/constant';
import { ShippingMethod } from '@utils/enums/ShippingMethod';
import { getCartSelector, getProfileSelector } from '@utils/selectors';
import { Link } from 'gatsby';
import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

const msgMaxWeight = `Max weight is 10kg. \nPlease contact us for assistance.`;
const msgMaxPrice = `Amount must be no more than $999,999.99.`;

const OrderReview = memo(
   ({ data, submitPayment, discount, localDiscount, countryCode }) => {
      const { cart, items } = useSelector(getCartSelector);
      const { uid, summary = 0, local_summary = 0, total = 0, local_total = 0, fee = 0 } =
         cart || {};

      const { shipping_type, address_uid, card_uid } = data || {};

      const [feesError, setFeesError] = useState('');
      const [priceError, setPriceError] = useState('');

      const [fees, setFees] = useState({
         [ShippingMethod.STANDARD]: null,
         [ShippingMethod.FAST]: null,
      });

      useEffect(() => {
         const loadFees = async () => {
            const response = await shippingService.getAllShippingFees({
               cart_uid: uid,
               country_code: countryCode,
               shipping_types: [ShippingMethod.STANDARD, ShippingMethod.FAST],
            });
            if (response.isSuccess) {
               setFees(response.data);
            } else {
               setFeesError(response.message);
            }
         };
         if (countryCode) {
            loadFees();
         }
      }, [uid, countryCode]);

      const canSubmit = useMemo(() => {
         // temp prevent checkout in VN
         if (countryCode === 'VN') return false;

         if (priceError || feesError) {
            return false;
         }
         if (!address_uid || !card_uid || !shipping_type) {
            return false;
         }
         return true;
      }, [feesError, priceError, shipping_type, address_uid, card_uid, countryCode]);

      // shipping fee
      const shippingFee = useMemo(() => {
         return fees[shipping_type] || null;
      }, [fees, shipping_type]);

      // total
      const { checkoutTotal, checkoutLocalTotal } = useMemo(() => {
         const checkoutTotal = total + (shippingFee?.fee || 0) - (discount || 0);
         const checkoutLocalTotal =
            local_total + (shippingFee?.local_fee || 0) - (localDiscount || 0);
         return {
            checkoutTotal: checkoutTotal > 0 ? checkoutTotal : 0,
            checkoutLocalTotal: checkoutLocalTotal > 0 ? checkoutLocalTotal : 0,
         };
      }, [total, local_total, shippingFee, discount, localDiscount]);

      useEffect(() => {
         if (checkoutTotal > constant.MAX_PAYMENT_TOTAL) {
            setPriceError(msgMaxPrice);
         } else {
            setPriceError('');
         }
      }, [checkoutTotal]);

      const hasCoupon = useMemo(() => {
         if (discount > 0 || localDiscount > 0) {
            return true;
         }
         return false;
      }, [discount, localDiscount]);

      return (
         <>
            <div className="order-review">
               <ItemList items={items} />
               <div className="checkout-review__amount">
                  <div className="checkout-review-row">
                     <div className="group-product-name">Subtotal</div>
                     <span className="price">
                        <Currency price={summary} localPrice={local_summary} />
                     </span>
                  </div>
                  {shippingFee && (
                     <div className="checkout-review-row">
                        <div className="group-product-name">
                           <span>Shipping</span>
                           {shippingFee.shipping_days && (
                              <span className="subtitle">
                                 {shippingFee.shipping_days} days delivery
                              </span>
                           )}
                        </div>
                        <span className="price">
                           +{' '}
                           <Currency
                              price={shippingFee.fee}
                              localPrice={shippingFee.local_fee}
                           />
                        </span>
                     </div>
                  )}
                  {hasCoupon && (
                     <div className="checkout-review-row">
                        <div className="group-product-name">
                           <span>Coupons applied</span>
                        </div>
                        <span className="price">
                           - <Currency price={discount} localPrice={localDiscount} />
                        </span>
                     </div>
                  )}
               </div>
               <div className="total-amount">
                  <label>Total</label>
                  <label className="price">
                     <Currency price={checkoutTotal} localPrice={checkoutLocalTotal} />
                  </label>
               </div>
            </div>
            <div className="checkout__submission">
               {(priceError || feesError) && (
                  <div className="checkout__submission--error">
                     <span>{priceError}</span>
                     <span>{feesError}</span>
                  </div>
               )}
               {countryCode === 'VN' && (
                  <div className="checkout__submission--notice">
                     We will support shipping in Vietnam in the near future. Now you can{' '}
                     <Link to={constant.ROUTE_CONTACT}>contact us</Link> for support.
                  </div>
               )}
               <button
                  className="checkout-btn"
                  disabled={!canSubmit}
                  onClick={submitPayment}
               >
                  ORDER
               </button>
            </div>
         </>
      );
   }
);

const ItemList = memo(({ items }) => {
   return (
      <div className="checkout-review__product-list">
         {items.map((item) => {
            return (
               <div className="checkout-review__product" key={item.uid}>
                  <span className="name">
                     {item.name} <span className="quantity">x{item.quantity}</span>
                  </span>
                  <span className="price">
                     <Currency price={item.total} localPrice={item.local_total} />
                  </span>
               </div>
            );
         })}
      </div>
   );
});

export default OrderReview;
